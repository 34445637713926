// eslint-disable-next-line import/no-cycle
import { defineStore } from 'pinia';
import {
	APIObjectType,
	type APITypeObjectId,
	EducationStatus,
	type IAPIEducationLevel
} from '@hokify/common';
import { getAsDate } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/get-as-date';
import { useUserProfileStore } from './user-profile';

export const useUserProfileEducationStore = defineStore('userProfileEducation', {
	state: () => ({}),
	actions: {
		async delEducation({
			educationId,
			force
		}: {
			educationId?: APITypeObjectId<APIObjectType.UserEducation>;
			force?: boolean;
		}) {
			if (educationId && !educationId.startsWith('education-new')) {
				try {
					const res = await this.$nuxt.$hokFetch(
						`/app-api/jobseeker/cv/education/${educationId}${force ? '?force=true' : ''}`,
						{ method: 'DELETE' }
					);

					if (res?.userUpdate) {
						const userProfileStore = useUserProfileStore();
						userProfileStore.updateElements(res.userUpdate);
					}
					return res;
				} catch (error: any) {
					console.error('delEducation failed', error);
					throw (error.response && error.response.data) || error.response || error;
				}
			}
		},
		// TODO education types need to be refactored -> we send different, incompatible types from components
		async syncEducationToDb(education: {
			_id?: APITypeObjectId<APIObjectType.UserEducation>;
			selectedAsHighlight?: boolean;
			school?: string;
			startDate?: Date;
			endDate?: Date;
			degree?: APITypeObjectId<APIObjectType.EducationDegree>;
			description?: string;
			isVisible?: boolean;
			status?: EducationStatus;
			level?: IAPIEducationLevel;
			sublevel?: {
				_id: APITypeObjectId<APIObjectType.EducationLevel>;
				value: string;
			};
		}) {
			const serverObj: {
				_id?: APITypeObjectId<APIObjectType.UserEducation>;
				selectedAsHighlight?: boolean;
				school?: string;
				startDate?: Date;
				endDate?: Date;
				degree?: APITypeObjectId<APIObjectType.EducationDegree>;
				description?: string;
				isVisible?: boolean;
				status?: EducationStatus;
				level?: APITypeObjectId<APIObjectType.EducationLevel>;
				sublevel?: APITypeObjectId<APIObjectType.EducationLevel>;
			} = {
				...education,
				level: education.level?._id,
				sublevel: education.sublevel?._id,
				startDate: education.startDate ? getAsDate(education.startDate) : undefined,
				endDate: education.endDate ? getAsDate(education.endDate) : undefined
			};

			try {
				const res = await this.$nuxt.$hokFetch('/app-api/jobseeker/cv/education', {
					method: 'POST',
					body: serverObj
				});

				if (res?.userUpdate) {
					const userProfileStore = useUserProfileStore();
					userProfileStore.updateElements(res.userUpdate);
					userProfileStore.sortCV('experience');
				}
				return res;
			} catch (error: any) {
				console.error('syncEducationToDb failed', error);
				throw (error.response && error.response.data) || error.response || error;
			}
		},
		async highlightEducation({ educationId, highlight }) {
			try {
				const res = await this.$nuxt.$hokFetch('/app-api/jobseeker/cv/education-highlight', {
					method: 'PUT',
					body: {
						educationHighlightId: educationId,
						highlight
					}
				});
				if (res?.userUpdate) {
					const userProfileStore = useUserProfileStore();
					userProfileStore.updateElements(res.userUpdate);
				}
				return res;
			} catch (error: any) {
				console.error('highlight education failed', error);
				throw (error.response && error.response.data) || error.response || error;
			}
		},
		async setHasNoEducation() {
			try {
				const res = await this.$nuxt.$hokFetch('/app-api/jobseeker/cv/has-no-education', {
					method: 'PUT'
				});
				if (res?.userUpdate) {
					const userProfileStore = useUserProfileStore();
					userProfileStore.updateElements(res.userUpdate);
				}
				return res;
			} catch (error: any) {
				console.error('setting has-no-education failed', error);
				throw (error.response && error.response.data) || error.response || error;
			}
		}
	}
});
